<template>
  <v-dialog
    scrollable
    persistent
    :width="600"
    v-model="dialog">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title class="white--text" v-text="$t('network.updateZitiusToken')" />
        <v-spacer />
        <v-btn color="white" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row class="pt-2">
            <v-col cols="12" class="pb-0">
              <v-textarea
                :value="oldToken"
                :label="$t('network.oldToken')"
                readonly
              />
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-textarea
                v-model="newToken"
                :label="$t('network.newToken')"
                :rules="newTokenRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-4 mr-4"
          @click="setZitiusToken"
        >
          {{ $t('common.update') }}
        </v-btn>

        <v-btn
          class="error px-4"
          @click.native="dialog = false">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SendManualOfferDialog',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      oldToken: {
        type: String,
        default: '',
      },
    },

    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    },

    data() {
      return {
        newToken: '',
        validForm: true,
        newTokenRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('network.newToken') }),
        ],
      }
    },

    watch: {
      dialog (value) {
        if (value) {
          this.newToken = ''
        }
      }
    },

    methods: {
      setZitiusToken () {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        this.$emit('set-zitius-token', this.newToken)
      },
    }
  }
</script>
