<template>
  <div>
    <div class="text-subtitle-1 font-weight-bold mb-2">
      <span>{{ $t('network.zitiusToken') }}:</span>
      <span class="red--text ml-2">{{ token ? $t('common.yes') : $t('common.no') }}</span>
      <v-btn
        small
        outlined
        color="primary"
        class="ml-3"
        @click.native="isShowUpdateTokenDialog = true"
      >
        {{ token ? $t('common.update') : $t('common.create') }}
      </v-btn>
    </div>

    <base-card>
      <v-card-text>
        <v-row class="align-end">
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="d-flex justify-md-end justify-sm-center pb-0 pb-sm-3"
          >
            <v-checkbox
              v-model="filterOptions.isCompleted"
              :label="$t('common.completed')"
              hide-details
              class="mt-0 mr-3"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            lg="3"
          >
            <v-text-field
              v-model="filterOptions.orderId"
              hide-details
              clearable
              :label="$t('order.orderId')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            lg="3"
          >
            <v-text-field
              v-model="filterOptions.email"
              hide-details
              clearable
              :label="$t('common.email')"
            />
          </v-col>
        </v-row>

        <v-row class="align-end">
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-btn
              class="primary px-4 mr-4"
              @click="currentPage = 1; loadOrderList()"
            >
              {{ $t("common.search") }}
            </v-btn>

            <v-btn
              class="error px-4"
              @click="resetFilter">
              {{ $t("common.reset") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="orderList"
          :items-per-page="paginationLimit"
          hide-default-footer
          class="eg-table-bnone eg-table-td-py-md mt-2 px-4"
        >
          <template v-slot:item.action="{item}">
            <div class="d-flex justify-space-between">
              <v-btn
                v-if="!item.orderId"
                class="ma-1"
                small
                color="primary"
                @click.native="createOrder(item)"
              >
                {{ $t("order.order") }}
              </v-btn>

              <!-- <v-btn
                class="ma-1"
                small
                color="info"
                @click.native="viewOrderDetail(item)"
              >
                {{ $t("order.viewDetail") }}
              </v-btn> -->
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          color="primary"
          :total-visible="7"
          circle
        />
      </v-card-actions>
    </base-card>

    <update-zitius-token-dialog
      v-model="isShowUpdateTokenDialog"
      :old-token="token"
      @set-zitius-token="setZitiusToken"
    />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  import UpdateZitiusTokenDialog from './UpdateZitiusTokenDialog'

  export default {
    metaInfo: {
      title: 'Zitius Web Orders',
    },

    components: {
      UpdateZitiusTokenDialog,
    },

    data () {
      return {
        token: null,
        isShowUpdateTokenDialog: false,
        orderList: [],
        paginationLimit: 10,
        currentPage: 1,
        totalPages: 0,
        headers: [{
          text: this.$t('order.orderId'),
          value: 'provisioningSystemOrderNumber',
          width: 140,
        }, {
          text: this.$t('price.customerName'),
          value: 'customerName',
          width: 160,
        }, {
          text: this.$t('common.phoneNumber'),
          value: 'customerMobilePhone',
          width: 140,
        }, {
          text: this.$t('common.email'),
          value: 'customerEmail',
          width: 160,
        }, {
          text: this.$t('network.serviceName'),
          value: 'servicePackageName',
          width: 140,
        }, {
          text: this.$t('address.address'),
          value: 'fullAddress',
          width: 180,
        }, {
          text: this.$t('order.orderDate'),
          value: 'orderDate',
          width: 120,
        }, {
          text: this.$t('common.action'),
          value: 'action',
          width: 100,
        }],
        util: util,
        filterOptions: {
          isCompleted: false,
          orderId: '',
          email: '',
        },
      }
    },

    watch: {
      currentPage (val) {
        this.currentPage = val
        this.loadOrderList()
      },
    },

    mounted () {
      this.loadZitiusToken()
      this.loadOrderList()
    },

    methods: {
      ...mapActions({
        getZitiusToken: 'getZitiusToken',
        updateZitiusToken: 'updateZitiusToken',
        fetchZitiusOrders: 'fetchZitiusOrders',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        createOrderFromWebOrder: 'createOrderFromWebOrder',
      }),

      async loadZitiusToken() {
        try {
          const response = await this.getZitiusToken()
          this.token = response?.token
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
      },

      async setZitiusToken(value) {
        this.isShowUpdateTokenDialog = false
        this.setLoadingText(`${this.$t('network.updatingZitiusToken')}...`)
        this.setLoading(true)

        try {
          const response = await this.updateZitiusToken(value)
          showSuccessDialog(this.$t('network.zitiusTokenUpdatedSuccessfully'))
          this.token = value
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      async loadOrderList() {
        const query = {
          email: this.filterOptions.email,
          orderNumber: this.filterOptions.orderId,
          isCompleted: this.filterOptions.isCompleted,
          offset: (this.currentPage - 1) * this.paginationLimit,
          limit: this.paginationLimit,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchZitiusOrders(query)

          this.totalPages = Math.ceil(response.count / this.paginationLimit)
          this.orderList = response.rows
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      viewOrderDetail(item) {
        console.log(item)
      },

      async createOrder(item) {
        this.setLoadingText(`${this.$t('order.creatingOrder')}...`)
        this.setLoading(true)
        try {
          const response = await this.createOrderFromWebOrder({
            orderNumber: item.provisioningSystemOrderNumber,
            type: 'zitius',
          })
          this.setLoading(false)
          await showSuccessDialog(this.$t('order.networkOrderedSuccessfully'))
          this.$router.push(`/order-price/${response.orderId}`)
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      resetFilter() {
        this.filterOptions = {
          email: '',
          orderId: '',
          isCompleted: false,
        }

        if (this.currentPage === 1) {
          this.loadOrderList()
          return
        }

        this.currentPage = 1
      },
    },
  }
</script>
